import { MouseEventHandler } from 'react'
import { v4 as uuid4 } from 'uuid'
import { getTokenData, LoginTokenData, TokenType } from './jwt'

const generateRequestId = (): string => uuid4().replaceAll('-', '')

const generateFingerprint = (): string => {
  const fingerprint = uuid4()
  localStorage.setItem('auth_fingerprint', fingerprint)
  return fingerprint
}

const getFingerprint = (): string => {
  return localStorage.getItem('auth_fingerprint') || generateFingerprint()
}

const getEmail = (): string | null => {
  let tokenData
  const emailFromLS = localStorage.getItem('auth_email')
  try {
    tokenData = getTokenData(TokenType.LOGIN) as LoginTokenData
  } catch {
    console.error('Invalid login token')
  }
  return (tokenData && tokenData.email) || emailFromLS
}

const updateEmail = (email: string) => {
  if (email !== localStorage.getItem('auth_email')) {
    localStorage.setItem('auth_email', email)
  }
}

const stopPropagationOnClick: MouseEventHandler<HTMLElement> = (e) => {
  e.stopPropagation()
}

const poundFormatter = new Intl.NumberFormat('en-UK', {
  style: 'currency',
  currency: 'GBP',
})

const dateFormatter = new Intl.DateTimeFormat('en-UK', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})

export {
  dateFormatter,
  generateFingerprint,
  generateRequestId,
  getEmail,
  getFingerprint,
  poundFormatter,
  stopPropagationOnClick,
  updateEmail,
}
