import { useQuery } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import WectoryLogoImage from "../../assets/images/wectoryLogo.svg";
import { H3 } from "../../components/Heading";

import {
  Application,
  GetPaymentSetupDataQueryDocument,
} from "../../datasources/graphql/operations";
import PaymentForm from "./components/payment-form";
import PaymentFormSkeleton from "./components/payment-form-skeleton";

type PaymentSetupProps = { className?: string };

export const PaymentSetupStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  max-width: 603px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;

  ${H3} {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 72px;
  }

  .checkbox-wrapper {
    margin-bottom: 60px;
  }

  .checkbox-wrapper span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: left;
  }

  .checkbox-wrapper div {
    margin-bottom: 36px;
  }

  button {
    width: auto;
    height: auto;
    border-radius: 15px;
    padding: 27px 70px;
  }
`;

export const StyledLogo = styled.img`
  box-sizing: border-box;
  margin-top: 40px;
  width: 146px;
  height: 34px;
`;

const PaymentSetup = ({ className }: PaymentSetupProps) => {
  /**
   * 👇👇👇 THIRD PARTY HOOKS 👇👇👇
   */

  const { id } = useParams();

  const { data, loading } = useQuery(GetPaymentSetupDataQueryDocument, {
    variables: { secret: id || "" },
  });

  const navigate = useNavigate();

  const accessToken = localStorage.getItem("auth_access_token");

  /**
   * if we get null from the query, it means that the user has already completed the payment setup
   */
  if (
    data?.getAgentsPaymentSetupData.agentAgreements === null &&
    data?.getAgentsPaymentSetupData.bankDetails === null &&
    data?.getAgentsPaymentSetupData.application === null
  ) {
    navigate("/payment-setup/success");
  }

  if (!accessToken) {
    navigate("/login");
  }

  return (
    <div className="max-w-[603px] m-auto px-8 pb-32">
      <Link to="/">
        <img
          src={WectoryLogoImage}
          alt="Wectory logo"
          className="w-[146px] h-[34px] my-16"
        />
      </Link>
      <div>
        <h1 className="text-4xl font-bold mb-8">Rental data confirmation</h1>
        {loading ? (
          <PaymentFormSkeleton />
        ) : (
          <PaymentForm
            agentAgreements={data?.getAgentsPaymentSetupData.agentAgreements!}
            bankDetails={data?.getAgentsPaymentSetupData.bankDetails!}
            application={
              data?.getAgentsPaymentSetupData.application! as Application
            }
            secret={id || ""}
            rentalPayment={
              data?.getAgentsPaymentSetupData.application?.rentalPayment!
            }
          />
        )}
      </div>
    </div>
  );
};

export default PaymentSetup;
