import styled from "styled-components";

const Block = styled.div`
  border-radius: 2rem;
  background-color: white;
  padding: 2rem;
  font-size: 1.4rem;
  line-height: 1.9rem;
  box-shadow: 0 0.2rem 1.5rem rgba(89, 89, 89, 0.1);
`;

const BaseBlock = (props: any) => <Block {...props} />;

export default BaseBlock;
