type ItemProps = {
  label?: string;
  value?: string | null;
};

const Item = ({ label, value }: ItemProps) => {
  if (!value) return null;

  return (
    <div className="flex flex-col gap-2 border-b pb-4">
      <span className="text-xl">{label}</span>
      <span className="text-2xl font-bold">{value}</span>
    </div>
  );
};

export default Item;
