import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { Loader } from "lucide-react";
import { poundFormatter } from "@/helpers";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { FixRentalPaymentByAgentDocument } from "@/datasources/graphql/operations";

type RentalPaymentModalProps = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  rentalPayment: number;
  secret: string;
};

const formSchema = z.object({
  rentalPaymentAmount: z
    .string()
    .transform((value) => parseFloat(value.replace(/[^\d.-]/g, "")))
    .refine((value) => !isNaN(value), "Invalid amount"),
});

type FormSchema = z.infer<typeof formSchema>;

const RentalPaymentModal = ({
  openModal,
  setOpenModal,
  rentalPayment,
  secret,
}: RentalPaymentModalProps) => {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const [fixRentalPaymentByAgent] = useMutation(
    FixRentalPaymentByAgentDocument
  );

  const navigation = useNavigate();

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    await fixRentalPaymentByAgent({
      variables: {
        secret,
        rentalPayment: data.rentalPaymentAmount,
      },
    });

    navigation("/payment-setup/to-the-landlord", { replace: true });
  };

  const formPaymentValue = form.watch("rentalPaymentAmount") || rentalPayment;
  const isValueEqual = formPaymentValue
    ? parseFloat(formPaymentValue.toString().replace(/[^\d.-]/g, "")) ===
      parseFloat(rentalPayment.toString())
    : false;

  return (
    <AlertDialog open={openModal}>
      <AlertDialogContent className="bg-white p-8 !rounded-3xl">
        <AlertDialogHeader>
          <AlertDialogTitle className="mb-4">
            Change rental payment amount
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription className="mb-4 block text-inherit">
          Enter the amount you are transferring to the landlord after deduction
          of the agency fee and other regular payments
        </AlertDialogDescription>
        <div className="max-w-xl">
          <Form {...form}>
            <form
              className="my-8 space-y-12"
              onSubmit={(e) => {
                e.stopPropagation();
                form.handleSubmit(onSubmit)(e);
              }}
              name="rental-payment-modal"
              id="rental-payment-modal"
            >
              <FormField
                control={form.control}
                name="rentalPaymentAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        id="rentalPaymentAmount"
                        type="text"
                        label="Enter rental payment amount"
                        defaultValue={poundFormatter.format(
                          Number(rentalPayment)
                        )}
                        onFocus={(e) => {
                          const parsedValue = Number(
                            e.target.value.replace(/[^\d.-]/g, "")
                          ).toString();
                          field.onChange(parsedValue);
                        }}
                        onBlur={(e) => {
                          const parsedValue = Number(
                            e.target.value.replace(/[^\d.-]/g, "")
                          );
                          if (!isNaN(parsedValue)) {
                            const formattedValue =
                              poundFormatter.format(parsedValue);
                            field.onChange(formattedValue);
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <AlertDialogDescription className="text-inherit mt-4">
                This information will be forwarded to the landlord and you will
                receive a corrected form
              </AlertDialogDescription>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => setOpenModal(false)}>
                  Cancel
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button
                    type="submit"
                    disabled={isValueEqual}
                    form="rental-payment-modal"
                  >
                    {form.formState.isSubmitting ? (
                      <Loader size={16} className="animate-spin" />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </form>
          </Form>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RentalPaymentModal;
