import { ApolloError, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetAgentQueryDocument } from "../../datasources/graphql/operations";

interface AgentData {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  code: string | null;
  sortCode: string | null;
  accountNumber: string | null;
  companyName: string | null;
  infoText: string;
}

interface UseAgentDataReturn extends AgentData {
  loading: boolean;
  error?: ApolloError;
}

const useAgentData = (): UseAgentDataReturn => {
  const [userData, setUserData] = useState<AgentData>({
    firstName: null,
    lastName: null,
    email: null,
    code: null,
    sortCode: null,
    accountNumber: null,
    companyName: null,
    infoText: "",
  });
  const { data, loading, error } = useQuery(GetAgentQueryDocument, {
    fetchPolicy: "network-only",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.agent) {
      if (!(data.agent.firstName && data.agent.lastName)) {
        navigate("/registration");
      }
      localStorage.setItem("agency", data.agent.agency ? "1" : "0");
      setUserData({
        firstName: data.agent.firstName || "",
        lastName: data.agent.lastName || "",
        email: data.agent.email || "",
        code: data.agent.rewardsCode ?? "",
        sortCode: data.agent.sortCode ?? "",
        accountNumber: data.agent.accountNumber ?? "",
        companyName: data.agent.agency?.title || null,
        infoText: data.agent.infoText,
      });
    } else if (error) {
      console.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  return {
    ...userData,
    loading,
    error,
  };
};

export { useAgentData };
