import { useState } from 'react'

const useCheckbox = (
  initialChecked: boolean, onCheck: (value: boolean) => void,
): [boolean, () => void] => {
  const [checked, setChecked] = useState(initialChecked)

  const toggle = () => {
    onCheck(!checked)
    setChecked(!checked)
  }

  return [checked, toggle]
}

export {
  useCheckbox,
}
