export const MAIN_CONDITIONS = "main_conditions";
export const TRANSFER_AMOUNT = "transfer_amount";
export const AGENT_AGREEMENTS = "agentAgreements";
export const RELIABLE_TENANT = "reliable_tenant";
export const PAYMENTS_SET_UP = "payments_set_up";

export const viewIds = [
  MAIN_CONDITIONS,
  TRANSFER_AMOUNT,
  AGENT_AGREEMENTS,
  RELIABLE_TENANT,
  PAYMENTS_SET_UP,
];
