import { useEffect, useState } from "react";
import { AgentDataType, UseRegisterType } from "../types";
import { RegisterAgentMutationDocument } from "../../../datasources/graphql/operations";
import { useMutation } from "@apollo/client";

const useRegister: UseRegisterType = () => {
  const [register, { data, error, loading, reset }] = useMutation(
    RegisterAgentMutationDocument
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (
      data?.fillAgentBasicInfo?.firstName &&
      data?.fillAgentBasicInfo?.lastName
    ) {
      setSuccess(true);
    } else if (error) {
      console.error(error.message);
    }
  }, [data, error]);

  const register_ = (data: AgentDataType) => {
    reset();
    // noinspection JSIgnoredPromiseFromCall
    register({ variables: { agentData: data } });
  };

  return { register: register_, success, loading, error, reset };
};

export default useRegister;
