import { useEffect, useState } from 'react'
import { GetAgentQueryDocument } from '../../../datasources/graphql/operations'
import { useQuery } from '@apollo/client'

type UseCheckExistentType = () => {
  loading: boolean,
  exist: boolean | null,
}

const useCheckExistent: UseCheckExistentType = () => {
  const [exist, setExist] = useState<boolean | null>(null)
  const { data, loading, error } = useQuery(GetAgentQueryDocument)

  useEffect(() => {
    setExist(
      Boolean(
        data && data.agent && data.agent.firstName && data.agent.lastName,
      ),
    )
  }, [data, error])

  return { loading, exist }
}

export default useCheckExistent
