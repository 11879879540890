import { useMutation } from "@apollo/client";
import {
  GetAgentQueryDocument,
  UpdateAgentMutationDocument,
} from "@/datasources/graphql/operations";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@radix-ui/react-dialog";
import { Loader2 } from "lucide-react";

export type AddUserDataProps = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  sortCode: string | null;
  accountNumber: string | null;
  close: () => void;
};

const schema = z.object({
  accountNumber: z
    .string()
    .nonempty()
    .length(8)
    .refine((value) => !isNaN(Number(value)), {
      message: "Account number must be a number",
    }),
  sortCode: z
    .string()
    .nonempty()
    .length(8)
    .refine((value) => !isNaN(Number(value.replace(/-/g, ""))), {
      message: "Sort code must be a number",
    }),
});

type AgentFormData = z.infer<typeof schema>;

const AddUserData = ({
  firstName,
  lastName,
  email,
  sortCode,
  accountNumber,
  close,
}: AddUserDataProps) => {
  const [updateAgent, { loading, error }] = useMutation(
    UpdateAgentMutationDocument,
    {
      refetchQueries: [GetAgentQueryDocument],
      onCompleted: () => {
        close();
      },
    }
  );
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      accountNumber: accountNumber || "",
      sortCode: sortCode || "",
    },
  });

  const onSubmit = (data: AgentFormData) => {
    updateAgent({
      variables: {
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        accountNumber: data.accountNumber,
        sortCode: data.sortCode.replace(/-/g, ""),
      },
    });
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle className="mb-8 text-left leading-snug text-balance">
          Update your bank account details
        </DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            name="accountNumber"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      label="Account Number"
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        if (value.length <= 8) {
                          return field.onChange(value.slice(0, 8));
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            name="sortCode"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      label="Sort Code"
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        let newValue = "";
                        for (let i = 0; i < value.length; i++) {
                          if (i === 2 || i === 4) {
                            newValue += "-" + value[i];
                          } else {
                            newValue += value[i];
                          }
                        }
                        return field.onChange(newValue.slice(0, 8));
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormMessage>
            {error?.message && "There was an error updating your bank details"}
          </FormMessage>
          <div className="flex justify-between">
            <DialogClose asChild>
              <Button type="button" variant="outline">
                Later
              </Button>
            </DialogClose>
            <Button type="submit">
              {loading ? (
                <Loader2 size={24} className="animate-spin" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default AddUserData;
