import {
  type ChangeEventHandler,
  type FormEventHandler,
  useState,
} from "react";
import { type ValidatorFunc } from "./types";

type UseTextInputType = (
  validator?: ValidatorFunc,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  onSubmit?: FormEventHandler<HTMLInputElement>
) => {
  isValid: boolean;
  update: ChangeEventHandler<HTMLInputElement>;
  submit: FormEventHandler<HTMLInputElement>;
};

const useTextInput: UseTextInputType = (validator, onChange, onSubmit) => {
  const [value_, setValue_] = useState("");

  const update: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue_(event.target.value);
    if (onChange) onChange(event);
  };

  const submit: FormEventHandler<HTMLInputElement> = (event) => {
    if (onSubmit) onSubmit(event);
  };

  const isValid = (): boolean => {
    if (value_ === "") return true;
    return validator ? validator(value_) : true;
  };

  return { isValid: isValid(), update, submit };
};

export { useTextInput };
