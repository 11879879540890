import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { FormSchema } from "./payment-form";
import { Loader } from "lucide-react";
import { ApolloError } from "@apollo/client";

type PaymentFormModalProps = {
  form: UseFormReturn<FormSchema>;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  loading: boolean;
  error?: ApolloError;
};

const PaymentFormModal = ({
  form,
  openModal,
  setOpenModal,
  loading,
  error,
}: PaymentFormModalProps) => {
  const handleOpenModal = async () => {
    const result = await form.trigger(["agreement", "docs"]);

    if (result) {
      setOpenModal(true);
    }
  };

  return (
    <AlertDialog open={openModal}>
      <AlertDialogTrigger onClick={handleOpenModal} asChild>
        <Button>Submit</Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="bg-white p-8 !rounded-3xl">
        <AlertDialogHeader>
          <AlertDialogTitle className="mb-4">
            Confirm Your Details
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription className="mb-4 block">
          All data and documents have been uploaded and are ready for
          submission.
        </AlertDialogDescription>
        <div className="max-w-xl">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormControl>
                  <Input
                    {...field}
                    label="First name"
                    autoFocus
                    form="payment-form"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} label="Last name" form="payment-form" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {error && (
          <p className="text-red-500 text-2xl block w-full">
            Something went wrong. Please check the form.
          </p>
        )}
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setOpenModal(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button type="submit" form="payment-form">
              {loading ? (
                <Loader size={16} className="animate-spin" />
              ) : (
                "Confirm"
              )}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default PaymentFormModal;
