import { useState } from 'react'

const useExpand = (initialExpanded: boolean) => {
  const [expanded, setExpanded] = useState(initialExpanded)

  const toggle = () => {
    setExpanded(!expanded)
  }

  return {
    expanded,
    toggle,
  }
}

export {
  useExpand,
}
