import { cn } from "@/components/lib/utils";
import { TableCell, TableRow } from "@/components/ui/table";
import { useCallback, useState } from "react";

type PaymentMethodRowProps = {
  title: string;
  value?: string | null;
};

const PaymentMethodRow = ({ title, value }: PaymentMethodRowProps) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(value || "");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <TableRow
      title="Click to copy"
      className="cursor-pointer"
      onClick={copyToClipboard}
    >
      <TableCell className="font-medium">{title}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell
        className={cn("text-right text-lg duration-150", {
          "opacity-1": copied,
          "opacity-0": !copied,
        })}
      >
        Copied
      </TableCell>
    </TableRow>
  );
};

export default PaymentMethodRow;
