import decodeJWT from "jwt-decode";

enum TokenType {
  LOGIN = "login",
  ACCESS = "access",
  REFRESH = "refresh",
}

interface TokenData {
  exp: number;
  subject: TokenType;
}

interface AccessTokenData extends TokenData {
  id: string;
}

interface RefreshTokenData extends TokenData {
  id: string;
}

interface LoginTokenData extends TokenData {
  attempts: number;
  email: string;
  session_id: string;
}

const keysMap = {
  [TokenType.LOGIN]: "auth_login_token",
  [TokenType.ACCESS]: "auth_access_token",
  [TokenType.REFRESH]: "auth_refresh_token",
};

class JWTDecodeError extends Error {}

const _afterTokenSave = (type: TokenType) => {
  switch (type) {
    case TokenType.LOGIN: {
      let tokenData;
      try {
        tokenData = getTokenData(type) as LoginTokenData;
      } catch (JWTDecodeError) {
        console.log("Error decoding login token");
      }
      if (tokenData) {
        localStorage.setItem("auth_email", tokenData.email);
      }
      break;
    }
  }
};

const saveToken = (type: TokenType, token = ""): void => {
  localStorage.setItem(keysMap[type], token);
  _afterTokenSave(type);
};

const getToken = (type: TokenType): string | null => {
  return localStorage.getItem(keysMap[type]);
};

const getTokenData = (
  type: TokenType
): AccessTokenData | RefreshTokenData | LoginTokenData | null => {
  const token = localStorage.getItem(keysMap[type]);
  if (token === null) return null;

  try {
    return decodeJWT(token);
  } catch (e) {
    localStorage.removeItem(keysMap[type]);
    throw JWTDecodeError;
  }
};

const hasToken = (type: TokenType): boolean =>
  Boolean(localStorage.getItem(keysMap[type]));

export {
  getTokenData,
  getToken,
  hasToken,
  JWTDecodeError,
  saveToken,
  TokenType,
};

export type { AccessTokenData, LoginTokenData, RefreshTokenData };
