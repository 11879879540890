import styled, { keyframes } from 'styled-components'
import SavedIcon from '../../../../assets/images/saved.svg'
import { stopPropagationOnClick } from '../../../../helpers'
import { media } from '../../../../styles'
import Button from '../../../Button'
import { useHint } from './hooks'

interface HintProps {
  error?: boolean
  children: string
}

const animationHide = keyframes`
  to {
    opacity: 0;
  }
`

const PageWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
  //noinspection CssInvalidPropertyValue
  height: -webkit-fill-available;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(39, 37, 42, 0.7);
  z-index: 2;

  ${media.desktop} {
    all: unset;
  }
`

const HintWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  width: 100%;
  height: min-content;
  padding: 5rem 2rem 6rem;
  border-radius: 2rem 2rem 0 0;
  background-color: white;

  ${media.desktop} {
    all: unset;
  }
`

const HintIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;

  ${media.desktop} {
    display: none;
  }
`

HintIcon.defaultProps = {
  src: SavedIcon,
}

const HintText = styled.span<HintProps>`
  color: ${props => props.error ? props.theme.error : props.theme.main};
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  opacity: 1;

  ${media.desktop} {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${props => props.error ? props.theme.error : props.theme.accent};
    animation: ${animationHide} 200ms ease-in 3s forwards;
  }
`

const HintButton = styled(Button)`
  ${media.desktop} {
    display: none;
  }
`

const Hint = ({ error, children }: HintProps) => {
  const { display, close } = useHint()

  if (!display) {
    return <div />
  }

  return (
    <PageWrapper onClick={close}>
      <HintWrapper onClick={stopPropagationOnClick}>
        <HintIcon />
        <HintText error={error}>{children}</HintText>
        <HintButton onClick={close}>Close</HintButton>
      </HintWrapper>
    </PageWrapper>
  )
}

export default Hint
