import styled from 'styled-components'
import { H1, H3 } from '../../components/Heading'
import Page from '../../components/Page'
import { useError, useNavigateBack } from './hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ErrorText = styled(H3)`
  font-weight: 500;
`

const LinkBack = styled.a`
  text-decoration: none;
  text-transform: none;
  margin-top: 2rem;
  width: max-content;
  color: ${props => props.theme.error};
  font-weight: 800;
`

const ErrorPage = () => {
  const errors = useError()
  const goBack = useNavigateBack()

  return (
    <Page>
      <Wrapper>
        <H1>ERROR</H1>
        <Errors>
          {
            errors
              ? errors.map((error, index) => (
                <ErrorText key={index}>{error}</ErrorText>
              ))
              : <ErrorText>'Something went wrong'</ErrorText>
          }
        </Errors>
        <LinkBack href='/' onClick={goBack}>Go back</LinkBack>
      </Wrapper>
    </Page>
  )
}

export default ErrorPage
