import styled from "styled-components";
import { poundFormatter } from "../../../../helpers";
import BaseBlock from "../BaseBlock";
import { useStatistics } from "./hooks";

const Block = styled(BaseBlock)`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemKey = styled.span``;

const ItemValue = styled.span``;

type StatisticsBlockProps = {
  className?: string;
};

const StatisticsBlock = ({ className }: StatisticsBlockProps) => {
  const statistics = useStatistics();

  return (
    <Block className={className}>
      <Item>
        <ItemKey>Sign ups with my code</ItemKey>
        <ItemValue>{statistics.signups}</ItemValue>
      </Item>
      <Item>
        <ItemKey>Applications</ItemKey>
        <ItemValue>{statistics.applications}</ItemValue>
      </Item>
      <Item>
        <ItemKey>Upfront payments</ItemKey>
        <ItemValue>{statistics.upfrontPayments}</ItemValue>
      </Item>
      <Item>
        <ItemKey>Sum of upfront payments</ItemKey>
        <ItemValue>
          {isNaN(Number(statistics.upfrontPaymentsSum))
            ? statistics.upfrontPaymentsSum
            : poundFormatter.format(Number(statistics.upfrontPaymentsSum))}
        </ItemValue>
      </Item>
    </Block>
  );
};

export default StatisticsBlock;
