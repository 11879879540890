import { ChangeEventHandler, FormEventHandler } from 'react'
import styled from 'styled-components'
import { useTextInput } from '../helpers/hooks'
import { ValidatorFunc } from '../helpers/types'

interface ErrorProps {
  error?: string
}

interface TextInputProps {
  validationError?: string
  placeholder?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onSubmit?: FormEventHandler<HTMLInputElement>
  validator?: ValidatorFunc
  disabled?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
`

const Input = styled.input<ErrorProps>`
  width: 100%;
  background-color: ${props => props.theme.pageBackground};
  font-size: 1.4rem;
  line-height: 1.9rem;
  padding: 1.4rem;
  border: ${props => (
    props.error ? `.2rem solid ${props.theme.error}` : '0 solid transparent'
  )};
  border-radius: 1rem;
  outline: none;
`

const ErrorText = styled.span`
  padding: .5rem 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${props => props.theme.error};
`

const TextInput = ({
  validationError,
  placeholder,
  validator,
  onChange,
  onSubmit,
  disabled,
  ...props
}: TextInputProps) => {
  const { isValid, update, submit } = (
    useTextInput(validator, onChange, onSubmit)
  )

  return (
    <Wrapper>
      <Input error={isValid ? undefined : validationError} type="text"
             placeholder={placeholder} onChange={update} onSubmit={submit}
             disabled={disabled} {...props} />
      {!isValid && <ErrorText>{validationError}</ErrorText>}
    </Wrapper>
  )
}


export default TextInput
