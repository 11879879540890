import { FocusEvent, FocusEventHandler } from 'react'

const isNum = (value: string) => !isNaN(Number(value))

const fillEmpty = (value: Array<string>) => {
  let result = []
  const valueLength = value.length
  for (let i = 0; i < 6; i++) {
    result.push(valueLength > i ? value[i] : '')
  }
  return result
}

const onFocus: FocusEventHandler = (e: FocusEvent<HTMLInputElement>) => {
  e.currentTarget.select()
}

export {
  fillEmpty,
  isNum,
  onFocus,
}
