import { HTMLProps, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset' | undefined
  className?: string
  children: ReactNode
  disabled?: boolean
  variant?: 'secondary' | 'primary'
}

const StyledButton = styled.button<ButtonProps>`
  width: 100%;
  height: 4.8rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 1.3rem;
  text-transform: none;
  color: #FFFFFF;
  border: none;
  background-color: ${props => props.theme.accent};
  box-shadow: 0 7px 15px rgba(52, 49, 173, 0.3);
  cursor: pointer;
  transition: box-shadow 300ms;

  &:hover {
    background-color: ${props => props.theme.accent};
    box-shadow: 0 7px 15px rgba(52, 49, 173, 0.4);
  }

  &:active {
    box-shadow: 0 2px 2px rgba(52, 49, 173, 0.3);
  }

  &:disabled {
    opacity: .5;
    cursor: default;
  }

  ${({ theme, variant }) => variant === 'secondary' && css`
    color: ${theme.textSecondary};
    background-color: transparent;
    box-shadow: none;

    &:hover, &:active {
      background-color: inherit;
      box-shadow: none;
    }

    &:disabled {
      opacity: inherit;
      cursor: default;
    }
  `}
`





const Button = (
  {
    className,
    children,
    disabled,
    type = 'button',
    ...props
  }: ButtonProps,
) => (
  <StyledButton type={type} className={className}
                disabled={disabled} {...props}>
    {children}
  </StyledButton>
)


export default Button
