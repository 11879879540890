import { useEffect } from "react";
import { getToken, saveToken, TokenType } from "../../../helpers/jwt";
import { ResendAuthCodeMutationDocument } from "../../../datasources/graphql/operations";
import { useMutation } from "@apollo/client";

const useResend = (): Function => {
  const [resendAuth, { data: resendAuthData, error: resendAuthError }] =
    useMutation(ResendAuthCodeMutationDocument);

  useEffect(() => {
    if (resendAuthData && resendAuthData.authResend) {
      saveToken(TokenType.LOGIN, resendAuthData.authResend);
    } else if (resendAuthError) {
      console.error(resendAuthError);
    }
  }, [resendAuthData, resendAuthError]);

  return () => {
    // noinspection JSIgnoredPromiseFromCall
    resendAuth({ context: { extraToken: getToken(TokenType.LOGIN) } });
  };
};

export default useResend;
