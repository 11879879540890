import { createContext, ReactElement } from 'react'

interface ModalContextValue {
  open: (content: ReactElement) => void
  close: () => void
}

// noinspection JSUnusedLocalSymbols
const ModalContext = createContext<ModalContextValue>({
  open: (content: ReactElement) => {},
  close: () => {},
})

export default ModalContext
