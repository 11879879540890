import Page from "../../components/Page";
import CodeBlock from "./components/CodeBlock";
import PayoutHistoryBlock from "./components/PayoutHistoryBlock";
import StatisticsBlock from "./components/StatisticsBlock";
import { useAgentData } from "./hooks";
import FaqBlock from "./components/FaqBlock";

const Dashboard = () => {
  const {
    firstName,
    lastName,
    sortCode,
    accountNumber,
    infoText,
    email,
    loading,
  } = useAgentData();

  return (
    <>
      <Page showProfile>
        <div className="grid md:[grid-template-columns:_1fr_1fr] lg:[grid-template-columns:_1fr_2fr] gap-8">
          <FaqBlock className="md:col-start-1" />
          <CodeBlock
            infoText={infoText}
            loading={loading}
            className="md:col-start-1"
          />
          <StatisticsBlock className="md:col-start-1" />
          <PayoutHistoryBlock
            className="md:col-start-2 md:row-span-full md:row-end-4"
            firstName={firstName}
            lastName={lastName}
            email={email}
            sortCode={sortCode}
            accountNumber={accountNumber}
            loading={loading}
          />
        </div>
      </Page>
    </>
  );
};

export default Dashboard;
