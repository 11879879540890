import { SVGProps } from "react";

const SvgCloseBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.403 4.403a.806.806 0 0 1 1.14 0L12.5 11.36l6.957-6.957a.806.806 0 0 1 1.14 1.14L13.64 12.5l6.957 6.957a.806.806 0 1 1-1.14 1.14L12.5 13.64l-6.957 6.957a.806.806 0 1 1-1.14-1.14L11.36 12.5 4.403 5.543a.806.806 0 0 1 0-1.14Z"
      fill="#27252A"
    />
  </svg>
);

export default SvgCloseBig;
