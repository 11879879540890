import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { hasToken, TokenType } from "../../../helpers/jwt";
import { emailOrUnsaved, formatError } from "../helpers";
import { Timer } from "../types";
import useCheck from "./useCheck";
import useCountdown from "./useCountdown";
import useQueryParams from "./useQueryCode";
import useResend from "./useResend";

interface UseConfirmResult {
  email: string;
  code: string;
  resendCode: () => void;
  timer: Timer;
  timerFinished: boolean;
  loading: boolean;
  error: string | null;
  fillCode: (value: string) => void;
}

const confirmedPath = "/?source=confirm&success=true";
const unconfirmedPath = "/?source=confirm&success=false";

const useConfirm = (timerSeconds: number): UseConfirmResult => {
  const [code, setCode] = useState("");
  const queryCode = useQueryParams();
  const resend = useResend();
  const { check, success, loading, error } = useCheck();
  const { timer, finished, restart } = useCountdown(timerSeconds);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!(hasToken(TokenType.LOGIN) || hasToken(TokenType.ACCESS))) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (queryCode) {
      setCode(queryCode);
    }
  }, [queryCode]);

  useEffect(() => {
    if (code.length === 6) {
      check(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (error) {
      const errorText = formatError(error);
      if (errorText === "Session expired") {
        timeout = setTimeout(() => {
          navigate(unconfirmedPath);
        }, 2000);
      }
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (success) {
      const paymentSetupId = searchParams.get("paymentSetupId");
      if (paymentSetupId) {
        navigate(`/payment-setup/${paymentSetupId}`);
      } else {
        navigate(confirmedPath);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const resendCode = () => {
    resend();
    restart();
  };

  const fill_ = (value: string) => {
    setCode(value);
  };

  return {
    email: emailOrUnsaved(),
    code,
    resendCode,
    timer,
    timerFinished: finished,
    loading,
    error: formatError(error),
    fillCode: fill_,
  };
};

export default useConfirm;
