import { PaymentData, PaymentsGroupedByMonths, RawPaymentData } from './types'

const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December',
]

const numToMonth = (num: number): string => months[num]

const dateToMonthTimestamp = (date: Date): number => (
  (new Date(date.getFullYear(), date.getMonth(), 1)).getTime()
)

const monthNameFromTimestamp = (timestamp: number): string => (
  months[(new Date(timestamp)).getMonth()]
)

const groupPaymentsByMonths = (
  payments: RawPaymentData[]
): PaymentsGroupedByMonths => {
  const grouped: { [key: number]: PaymentData[] } = {}
  payments.forEach(({ date, amount, status }) => {
    const date_ = new Date(date)
    const month = dateToMonthTimestamp(date_)
    if (grouped[month]) {
      grouped[month].push({ date: date_, amount, status })
    } else {
      grouped[month] = [{ date: date_, amount, status }]
    }
  })
  const ordered: {[key: number]: PaymentData[]} = {}
  Object.keys(grouped).map(Number).sort().reverse().forEach(
    (key) => ordered[key] = grouped[key],
  )
  return ordered
}

export {
  groupPaymentsByMonths,
  monthNameFromTimestamp,
  numToMonth,
}
