import * as React from "react";

import { Label } from "./label";
import { cn } from "../lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, ...props }, ref) => {
    const inputId = React.useId();
    return (
      <div className="relative">
        <input
          type={type}
          className={cn(
            "peer flex h-24 w-full rounded-xl bg-background px-4 pt-4 text-2xl border border-border",
            "ring-transparent ring-offset-background file:border-0 file:bg-transparent",
            "file:text-xl file:font-medium file:py-4 placeholder:text-muted-foreground",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-200 focus-visible:ring-offset-0",
            "disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          placeholder=" "
          {...props}
          id={inputId}
        />
        <Label
          htmlFor={inputId}
          className={cn(
            "transition-top absolute left-4 top-1/4 -translate-y-1/2 transform text-primary/40 duration-150 ease-out",
            "peer-placeholder-shown:top-1/2 peer-focus:top-1/4"
          )}
        >
          {label}
        </Label>
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
