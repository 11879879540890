import styled from "styled-components";
import { stopPropagationOnClick } from "../../../../helpers";
import { media } from "../../../../styles";
import { H3 } from "../../../Heading";
import { useLogOut } from "./hooks/useLogOut";
import Item from "./components/Item";
import { ChevronRight, X } from "lucide-react";
import { GetAgentQueryDocument } from "@/datasources/graphql/operations";
import { useQuery } from "@apollo/client";
import { Button } from "@/components/ui/button";

const PageWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(39, 37, 42, 0.7);
  z-index: 2;

  ${media.desktop} {
    background-color: inherit;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 10rem;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 2rem 0 1rem 0;
  // border-radius: 2rem 2rem 0 0;
  background-color: ${(props) => props.theme.background};
  box-shadow: 0 0.2rem 1.5rem rgba(89, 89, 89, 0.1);
  overflow-y: auto;

  ${media.desktop} {
    width: 37.5rem;
    right: 2rem;
    top: 2rem;
    bottom: unset;
    height: min-content;
    border-radius: 2rem;
    max-height: 90vh;
  }
`;

Wrapper.defaultProps = {
  onClick: stopPropagationOnClick,
};

const Title = styled(H3)`
  padding: 0 2rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  height: 5.4rem;
  font-size: 1.4rem;
  line-height: 1.9rem;
  font-weight: bold;

  & > a {
    text-decoration: none;
  }
`;

const SignOutButton = styled.a`
  color: ${(props) => props.theme.error};
`;

const DeleteAccountButton = styled.span`
  color: ${(props) => props.theme.textSecondary};
  cursor: pointer;
`;

interface SettingsProps {
  close: () => void;
  onDelete: () => void;
}

const Settings = ({ close, onDelete }: SettingsProps) => {
  const { data } = useQuery(GetAgentQueryDocument);
  const { logout } = useLogOut();

  return (
    <PageWrapper onClick={close}>
      <Wrapper>
        <div className="flex justify-between items-center pr-8">
          <Title>Profile</Title>
          <Button
            variant="outline"
            onClick={close}
            type="button"
            className="px-0 py-0 md:hidden"
          >
            <X color="black" />
          </Button>
        </div>
        <div className="flex flex-col gap-4 p-8 space-y-4">
          <Item label="Company name" value={data?.agent.agency?.title} />
          <Item label="First name" value={data?.agent.firstName} />
          <Item label="Surname" value={data?.agent.lastName} />
          <Item label="Email" value={data?.agent.email} />
          <Item label="Account number" value={data?.agent.accountNumber} />
          <Item label="Sort code" value={data?.agent.sortCode} />
          <div className="flex flex-col gap-2 border-b pb-4">
            <span className="text-xl">Help</span>
            <a
              href="mailto:agent@wectory.com"
              className="flex justify-between text-2xl font-bold"
            >
              Contact us
              <ChevronRight className="w-6 h-6" />
            </a>
          </div>
          <ActionsWrapper>
            <SignOutButton href="/" onClick={logout}>
              Sign Out
            </SignOutButton>
            <DeleteAccountButton onClick={onDelete}>
              Delete my account
            </DeleteAccountButton>
          </ActionsWrapper>
        </div>
      </Wrapper>
    </PageWrapper>
  );
};

export default Settings;
