import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const useQueryParams = (): string | null => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [code, setCode] = useState('')

  useEffect(() => {
    const code = searchParams.get('code')
    if (!code) return

    setCode(code)

    const sp = new URLSearchParams(searchParams)
    sp.delete('code')
    setSearchParams(sp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return code
}

export default useQueryParams
