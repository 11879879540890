import { ApolloClient, from, fromPromise, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
// @ts-expect-error
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import config from "../../config";
import { generateRequestId, getFingerprint } from "../../helpers";
import { getToken, TokenType } from "../../helpers/jwt";
import DebounceLink from "apollo-link-debounce";
import { RefreshTokenResult } from "./operations";
import { REFRESH_TOKEN_MUTATION } from "./operations/mutations/refreshTokenMutation";
import { matchPath } from "react-router-dom";
import jwtDecode from "jwt-decode";

const doNotRefreshOperations = ["auth", "checkCode", "refresh", "update"];

let currentRefreshRequest: Promise<RefreshTokenResult> | null = null;

const refreshToken = () => {
  if (!currentRefreshRequest) {
    currentRefreshRequest = graphqlClient
      .mutate({
        mutation: REFRESH_TOKEN_MUTATION,
        variables: { fingerprint: getFingerprint() },
        context: { extraToken: getToken(TokenType.REFRESH) },
      })
      .then(({ data }) => {
        currentRefreshRequest = null;
        return data;
      })
      .catch((err) => {
        currentRefreshRequest = null;
        throw err;
      });
  }

  return currentRefreshRequest;
};

const httpLink = createUploadLink({
  uri: config.GRAPHQL_URL,
});

const authLink = setContext(
  (request, { headers, extraHeaders, extraToken }) => {
    const token = extraToken || localStorage.getItem("auth_access_token");
    // if (!token) {
    //   window.location.href = "/login";
    // }
    return {
      headers: {
        ...headers,
        ...(extraHeaders || {}),
        "x-request-id": generateRequestId(),
        authorization: token ? `Bearer ${token}` : "",
        ...(process.env.NODE_ENV === "development"
          ? { "X-Real-IP": "127.0.0.1" }
          : {}),
        "X-Fingerprint": getFingerprint(),
      },
    };
  }
);

const errorLink = onError(({ graphQLErrors, operation, forward }): any => {
  if (graphQLErrors) {
    const error = graphQLErrors[0];
    const confirmRoute = matchPath("/confirm", window.location.pathname);

    if (
      (error.extensions.code === "FORBIDDEN" ||
        error.extensions.code === "UNAUTHENTICATED") &&
      !confirmRoute
    ) {
      if (doNotRefreshOperations.includes(operation.operationName)) {
        return forward(operation);
      }
      const token = localStorage.getItem("auth_access_token");

      const paymentSetupRoute = matchPath(
        "/payment-setup/:id",
        window.location.pathname
      );

      const loginUrl = paymentSetupRoute
        ? `/login?paymentSetupId=${paymentSetupRoute.params.id}`
        : "/login";

      if (!token) {
        return (window.location.href = loginUrl);
      }

      const expiredDate = jwtDecode<{ exp: number }>(token)?.exp;

      const shouldRefresh = expiredDate && expiredDate * 1000 < Date.now();

      if (shouldRefresh) {
        const refreshTokenObservable = fromPromise(
          refreshToken().catch((err) => {
            console.error(err);

            window.location.href = loginUrl;
          })
        ).filter((data) => Boolean(data));

        return (
          refreshTokenObservable
            // @ts-ignore
            .flatMap((data: { refreshToken?: RefreshTokenResult }) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${data.refreshToken?.jwtAccess}`,
                },
              });

              localStorage.setItem(
                "auth_refresh_token",
                data.refreshToken?.jwtRefresh ?? ""
              );
              localStorage.setItem(
                "auth_access_token",
                data.refreshToken?.jwtAccess ?? ""
              );

              return forward(operation);
            })
        );
      } else if (paymentSetupRoute && !shouldRefresh) {
        window.location.href = "/"; // redirect to main page
      }
    }
  }
});

const DEFAULT_DEBOUNCE_TIMEOUT = 100;
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

const graphqlClient = new ApolloClient({
  link: from([errorLink, authLink, debounceLink, httpLink]),
  cache: new InMemoryCache(),
});

export default graphqlClient;
