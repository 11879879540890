import { MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'

type ClickBackFunction = MouseEventHandler<HTMLElement>

const useNavigateBack = (): ClickBackFunction => {
  const navigate = useNavigate()

  return () => {
    navigate(-1)
  }
}

export default useNavigateBack
