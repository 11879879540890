import { ValidatorFunc } from '../types'
import {
  accountNumberValidator,
  sortCodeValidator,
} from './billingInfoValidators'
import emailValidator from './emailValidator'
import nameValidator from './nameValidator'

const notEmptyValidator: ValidatorFunc = (value) => value.length > 0

export {
  accountNumberValidator,
  emailValidator,
  nameValidator,
  notEmptyValidator,
  sortCodeValidator,
}
