import { useEffect, useState } from 'react'
import { GetStatisticsQueryDocument } from '../../../../datasources/graphql/operations'
import { useQuery } from '@apollo/client'

interface StatisticsData {
  signups: string
  applications: string
  upfrontPayments: string
  upfrontPaymentsSum: string
  rewardAmount: string
}

const useStatistics = (): StatisticsData => {
  const [statistics, setStatistics] = useState<StatisticsData>({
    signups: '-',
    applications: '-',
    upfrontPayments: '-',
    upfrontPaymentsSum: '-',
    rewardAmount: '-',
  })
  const { data, loading, error } = useQuery(GetStatisticsQueryDocument)

  useEffect(() => {
    if (data?.rewardsStatistic) {
      setStatistics({
        signups: data.rewardsStatistic.landlordsSignedUp?.toString() || "-",
        applications: data.rewardsStatistic.landlordsApplicationsCount?.toString() || "-",
        upfrontPayments: data.rewardsStatistic.landlordsPaidUpfrontCount?.toString() || "-",
        upfrontPaymentsSum: data.rewardsStatistic.landlordsPaidUpfrontSum || "-",
        rewardAmount: data.rewardsStatistic.bonusTotal || "-",
      })
    } else if (loading) {
      setStatistics({
        signups: 'loading...',
        applications: 'loading...',
        upfrontPayments: 'loading...',
        upfrontPaymentsSum: 'loading...',
        rewardAmount: 'loading...',
      })
    } else if (error) {
      setStatistics({
        signups: 'error',
        applications: 'error',
        upfrontPayments: 'error',
        upfrontPaymentsSum: 'error',
        rewardAmount: 'error',
      })
    }
  }, [data, loading, error])

  return statistics
}

export {
  useStatistics,
}
