import styled from "styled-components";
import BaseBlock from "../BaseBlock";
import Name from "./components/Name";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import GenerateCode from "./generate-code";
import ShowCodes from "./show-codes";
import { Button } from "@/components/ui/button";
import { useMutation, useQuery } from "@apollo/client";
import {
  GenerateOneTimeRewardsCodeDocument,
  GetRewardsCodesUsesDocument,
} from "@/datasources/graphql/operations";
import { useCallback, useState } from "react";
import { Loader2 } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
// import AddUserData from "./add-user-data";

interface CodeBlockProps {
  infoText: string;
  loading: boolean;
  className?: string;
}

const Block = styled(BaseBlock)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.textMain};
`;

const CodeBlock = ({
  infoText,
  loading: agentDataLoading,
  className,
}: CodeBlockProps) => {
  const [generateCode, { data, loading }] = useMutation(
    GenerateOneTimeRewardsCodeDocument
  );

  const { data: codes, loading: codesLoading } = useQuery(
    GetRewardsCodesUsesDocument,
    {
      fetchPolicy: "network-only",
    }
  );

  // const dataIsOkState = Boolean(sortCode && accountNumber);

  const [isOpen, setIsOpen] = useState(false);

  const generateCodeHandle = useCallback(() => {
    generateCode();
    // if (dataIsOkState) {
    //   generateCode();
    // }
  }, [generateCode]);

  if (agentDataLoading) {
    return (
      <Block className={className}>
        <Name>
          <Skeleton className="w-[70%] h-8" />
        </Name>
        <div className="grid [grid-template-columns:_1fr_2fr] gap-4">
          <Skeleton className="h-4" />
          <Skeleton className="h-4" />
          <Skeleton className="h-4" />
          <Skeleton className="h-4" />
          <Skeleton className="h-4" />
          <Skeleton className="h-4" />
          <Skeleton className="h-4" />
          <Skeleton className="h-4" />
        </div>
        <Skeleton className="h-16" />
      </Block>
    );
  }

  return (
    <Block className={className}>
      <Name>Referral Rewards Programme</Name>
      <p className="py-4 grow">{infoText}</p>
      <div className="flex flex-col gap-8">
        <Dialog>
          <DialogTrigger asChild>
            <Button onClick={generateCodeHandle} className="min-w-[250px]">
              {loading ? (
                <Loader2 size={24} className="animate-spin" />
              ) : (
                "Generate referral code"
              )}
            </Button>
          </DialogTrigger>
          <DialogContent className="w-full max-w-full h-full block md:h-auto md:grid !rounded-none md:max-w-32 md:min-w-[545px] p-8 md:!rounded-3xl">
            <GenerateCode
              code={data?.generateOneTimeRewardsCode || ""}
              loading={loading}
            />
          </DialogContent>
        </Dialog>
        {Boolean(codes?.getRewardsCodesUses?.length) && (
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger className="mt-4 lg:mt-0 text-right">
              Show all referral codes
            </DialogTrigger>
            {isOpen && <ShowCodes codes={codes} loading={codesLoading} />}
          </Dialog>
        )}
      </div>
    </Block>
  );
};

export default CodeBlock;
