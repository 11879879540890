import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { monthNameFromTimestamp } from '../../helpers'
import { PaymentData } from '../../types'
import Payments from '../Payments'
import { useExpand } from './logic'

interface AccordionProps {
  items: { [key: number]: PaymentData[] }
}

interface AccordionItemProps {
  initialExpanded?: boolean
  headerText: string
  children: ReactNode
}

interface AccordionItemStyleProps {
  expanded: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const AccordionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AccordionItemHeader = styled.div<AccordionItemStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2rem;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.4rem;
  border-bottom: .1rem solid black;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
    border-bottom: .2rem solid black;
    border-right: .2rem solid black;
    transform-origin: 75% 75%;
    transform: ${props => props.expanded ? 'rotate(45deg)' : 'rotate(-45deg)'};
    transition: transform 200ms;
  }
`

const AccordionItemContent = styled.div<AccordionItemStyleProps>`
  display: flex;
  align-items: end;
  height: 0;
  line-height: 0;
  padding: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  transition: all 200ms;

  ${props => props.expanded && css`
    height: min-content;
    line-height: inherit;
    padding: 2rem 0;
    margin-bottom: 0;
  `}
`

const AccordionItem = ({
  initialExpanded,
  headerText,
  children,
}: AccordionItemProps) => {
  const { toggle, expanded } = useExpand(initialExpanded || false)

  return (
    <AccordionItemWrapper>
      <AccordionItemHeader expanded={expanded} onClick={toggle}>
        {headerText}
      </AccordionItemHeader>
      <AccordionItemContent expanded={expanded}>
        {children}
      </AccordionItemContent>
    </AccordionItemWrapper>
  )
}

const Accordion = ({ items }: AccordionProps) => {
  return (
    <Wrapper>
      {Object.keys(items).map(Number).map((month, index) => (
        <AccordionItem key={`accordion_item_${index}`}
                       headerText={monthNameFromTimestamp(month)}
                       initialExpanded={index === 0}>
          <Payments payments={items[month]} />
        </AccordionItem>
      ))}
    </Wrapper>
  )
}

export default Accordion
