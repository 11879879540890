import BaseBlock from "../BaseBlock";
import Name from "../CodeBlock/components/Name";
import HowToUseModal from "./how-to-use-modal";

type FaqBlockProps = {
  className?: string;
};

const FaqBlock = ({ className }: FaqBlockProps) => {
  return (
    <BaseBlock className={className}>
      <div>
        <Name>Getting Started Guide</Name>
        <p className="py-4 grow lg:text-balance">
          Comprehensive guide to getting started, managing your referrals, and
          tracking your commissions.
        </p>
      </div>
      <div className="flex justify-between items-center">
        <HowToUseModal />
      </div>
    </BaseBlock>
  );
};

export default FaqBlock;
