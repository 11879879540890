import styled from "styled-components";
import { onFocus } from "./helpers";
import { useCodeInput } from "./hooks";

type WrapperProps = {
  error?: string | null;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  height: 6.2rem;
`;

const Number = styled.input`
  width: 4.6rem;
  height: 6.2rem;
  padding: 0;
  margin: 0;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.pageBackground};
  color: ${(props) => props.theme.accent};
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3.2rem;
  text-align: center;
  caret-color: transparent;

  &::placeholder {
    color: ${(props) => props.theme.textMain};
    opacity: 1;
  }

  &:focus {
    border-color: ${(props) => props.theme.accent};
  }

  &::selection {
    background-color: transparent;
  }
`;

Number.defaultProps = {
  maxLength: 1,
  placeholder: "•",
  type: "tel", // workaround for android chrome bug (https://bugs.chromium.org/p/chromium/issues/detail?id=118639)
  onFocus,
};

type CodeInputProps = {
  disabled?: boolean;
  code: string;
  onChange?: () => void;
  error?: string | null;
  onFill: (value: string) => void;
};

const CodeInput = ({
  disabled,
  code,
  onChange = () => {},
  error,
  onFill,
}: CodeInputProps) => {
  const { values, keyUp, keyDown, change, onPaste } = useCodeInput(
    code,
    onChange,
    onFill
  );

  return (
    <Wrapper error={error}>
      {values.map(({ value, ref }, i) => (
        <Number
          key={i}
          ref={ref}
          onKeyUp={keyUp}
          onKeyDown={keyDown}
          disabled={disabled}
          value={value}
          onPaste={onPaste}
          onChange={change}
        />
      ))}
    </Wrapper>
  );
};

export default CodeInput;
