import styled from "styled-components";
import { TextChildProps } from "../helpers/propTypes";
import { media } from "../styles";

const H = styled.span<TextChildProps>`
  font-weight: 900;
`;

const H1 = styled(H)`
  font-size: 2.4rem;
  line-height: 3.2rem;

  ${media.desktop} {
    font-size: 4rem;
    line-height: 5.4rem;
  }
`;

const H2 = styled(H)`
  font-size: 3.2rem;
`;

const H3 = styled(H)`
  font-size: 2rem;

  ${media.desktop} {
    font-size: 2.4rem;
  }
`;

const H4 = styled(H)`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.9rem;

  ${media.desktop} {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.4rem;
  }
`;

export { H1, H2, H3, H4 };
