import { useState } from 'react'

interface UseHintResult {
  display: boolean
  close: () => void
}

const useHint = (): UseHintResult => {
  const [display, setDisplay] = useState<boolean>(true)

  const close = () => {
    if (display) {
      setDisplay(false)
    }
  }

  return { display, close }
}

export {
  useHint,
}
