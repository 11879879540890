import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
} from "@/components/ui/table";
import { useState } from "react";

const ShowCodes = ({ codes, loading }: { codes: any; loading: boolean }) => {
  const [copied, setCopied] = useState<string | null>(null);

  return (
    <DialogContent
      forceMount
      className="!w-full max-w-none h-full md:min-w-[545px] md:max-w-[32rem] md:h-auto min-h-[300px] md:!rounded-3xl p-8 block"
    >
      <DialogHeader className="mb-8 text-left">
        <DialogTitle>Your referral codes</DialogTitle>
      </DialogHeader>

      {loading ? (
        <div className="space-y-8">
          <Skeleton className="h-8 w-full mb-2" />
          <Skeleton className="h-8 w-full mb-2" />
          <Skeleton className="h-8 w-full mb-2" />
          <Skeleton className="h-8 w-full mb-2" />
          <Skeleton className="h-8 w-full mb-2" />
        </div>
      ) : (
        <div className="max-h-[500px] overflow-y-auto relative">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell className="font-bold">Code</TableCell>
                <TableCell className="font-bold">Created At</TableCell>
                <TableCell className="font-bold">Used By</TableCell>
                <TableCell className="font-bold">Used On</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {codes?.getRewardsCodesUses?.map((codeUse: any) => (
                <TableRow
                  key={codeUse.code}
                  className="cursor-pointer"
                  onClick={() => {
                    setCopied(codeUse.code);
                    navigator.clipboard.writeText(codeUse.code);
                    setTimeout(() => {
                      setCopied(null);
                    }, 2000);
                  }}
                  title="Click to copy"
                >
                  <TableCell className="min-w-[100px]">
                    {copied === codeUse.code ? (
                      <span className="text-accent">Copied 👌</span>
                    ) : (
                      codeUse.code
                    )}
                  </TableCell>
                  <TableCell>
                    {new Date(codeUse.createdAt)
                      .toLocaleString("en-GB", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                      .replaceAll("/", ".")}
                  </TableCell>
                  <TableCell>{`${codeUse?.usedBy?.firstName || ""} ${
                    codeUse?.usedBy?.lastName || ""
                  }`}</TableCell>
                  <TableCell>
                    {codeUse.usedAt
                      ? new Date(codeUse.usedAt)
                          .toLocaleString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                          })
                          .replaceAll("/", ".")
                      : "unused"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </DialogContent>
  );
};

export default ShowCodes;
