import styled from "styled-components";
import Button from "../../components/Button";
import { H1 } from "../../components/Heading";
import LoginWrapper from "../../components/LoginWrapper";
import Page from "../../components/Page";
import TextInput from "../../components/TextInput";
import { stopPropagationOnClick } from "../../helpers";
import { nameValidator, notEmptyValidator } from "../../helpers/validators";
import { media } from "../../styles";
import AgreementCheckbox from "./components/AgreementCheckbox";
import { useRegistration } from "./hooks";

const Error = styled.div`
  width: max-content;
  font-size: 1.6rem;
  font-weight: 900;
  color: ${(props) => props.theme.error};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  ${media.desktop} {
    width: 35.3rem;
  }
`;

const Input = styled(TextInput)`
  background-color: ${(props) => props.theme.background};
`;

const InlineLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.accent};
`;

InlineLink.defaultProps = {
  target: "_blank",
  onClick: stopPropagationOnClick,
};

const Registration = () => {
  const {
    toggleAgreement,
    formFilled,
    onChange: { changeFirstName, changeLastName, changeCompanyName },
    submit,
    loading,
    error,
    companyName,
    companyLoading,
    companyNameError,
  } = useRegistration();

  return (
    <Page>
      <LoginWrapper>
        <H1>Create your account</H1>
        <Form onSubmit={submit}>
          {/* <Switch values={["Agent", "Company"]} onChange={toggleIsCompany} /> */}
          <Input
            validator={nameValidator}
            validationError="Invalid value"
            onChange={changeFirstName}
            placeholder="Your First name"
            disabled={loading}
          />
          <Input
            validator={nameValidator}
            validationError="Invalid value"
            onChange={changeLastName}
            placeholder="Your Surname"
            disabled={loading}
          />
          <Input
            validator={notEmptyValidator}
            validationError="Invalid value"
            onChange={changeCompanyName}
            placeholder="Company registration number"
            disabled={loading}
          />
          <span>
            {companyLoading
              ? "Searching..."
              : companyName || companyNameError}
          </span>
          <AgreementCheckbox onCheck={toggleAgreement}>
            {"By registering, you are agreeing to our "}
            <InlineLink href="https://www.wectory.com/pp">
              Privacy&nbsp;Policy
            </InlineLink>
            {" and signing our "}
            <InlineLink href="https://www.wectory.com/ara">
              Terms&nbsp;of&nbsp;Use
            </InlineLink>
            {"."}
          </AgreementCheckbox>
          {error && <Error>{error}</Error>}
          <Button type="submit" disabled={loading || !formFilled}>
            Done
          </Button>
        </Form>
      </LoginWrapper>
    </Page>
  );
};

export default Registration;
