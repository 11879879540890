import { MouseEventHandler, useEffect, useState } from 'react'
import { DeleteUserMutationDocument } from '../../../../datasources/graphql/operations'
import { useMutation } from '@apollo/client'

interface UseDeleteUserResult {
  deleteUser: MouseEventHandler<HTMLElement>
  loading: boolean
  error: string | null
}

const deletedSuccessfully = () => {
  localStorage.clear()
  window.location.href = '/'
}

const useDeleteUser = (): UseDeleteUserResult => {
  const [deleteUser, {
    data,
    loading,
    error: mutationError,
    reset,
  }] = useMutation(DeleteUserMutationDocument)
  const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    if (data && data.deleteUser) {
      if (data.deleteUser.success) {
        deletedSuccessfully()
      } else {
        setError(data.deleteUser.reason as string)
      }
    }
  }, [data])
  useEffect(() => {
    if (mutationError) {
      setError('Something went wrong')
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationError])

  const deleteOnClick: MouseEventHandler<HTMLElement> = () => {
    // noinspection JSIgnoredPromiseFromCall
    deleteUser()
  }

  return {
    deleteUser: deleteOnClick,
    loading,
    error,
  }
}

export {
  useDeleteUser,
}
