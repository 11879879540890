import { Table, TableBody } from "@/components/ui/table";
import {
  AgentsPaymentSetupData,
  BankDetails,
} from "@/datasources/graphql/operations";
import PaymentMethodRow from "./payment-method-row";

type BankDetailsTableProps = {
  bankDetails: BankDetails;
  application: AgentsPaymentSetupData["application"];
};

const BankDetailsTable = ({
  bankDetails,
  application,
}: BankDetailsTableProps) => {
  return (
    <Table>
      <TableBody>
        <PaymentMethodRow title="Company Name" value={bankDetails.fullName} />
        <PaymentMethodRow
          title="Account Number"
          value={bankDetails.accountNumber}
        />
        <PaymentMethodRow title="Sort Code" value={bankDetails.sortCode} />
        <PaymentMethodRow
          title="Reference"
          value={bankDetails.paymentReference}
        />
        <PaymentMethodRow
          title="First Payment Date"
          value={application?.startDate}
        />
        <PaymentMethodRow
          title="Last Payment Date"
          value={application?.endDate}
        />
        <PaymentMethodRow title="Frequency" value="Monthly" />
      </TableBody>
    </Table>
  );
};

export default BankDetailsTable;
