import { Skeleton } from "@/components/ui/skeleton";

const PaymentFormSkeleton = () => {
  return (
    <>
      <Skeleton className="w-full h-8 mb-4" />
      <Skeleton className="w-[70%] h-8 mb-4" />
      <Skeleton className="w-[50%] h-8 mb-12" />
      <Skeleton className="w-[70%] h-8 mb-4" />
      <Skeleton className="w-[70%] h-16 mb-4" />
      <Skeleton className="w-[70%] h-8 mb-12" />
      <Skeleton className="w-full h-8 mb-4" />
      <Skeleton className="w-[70%] h-8 mb-4" />
      <Skeleton className="w-[50%] h-8 mb-12" />
      <Skeleton className="w-[70%] h-8 mb-4" />
      <Skeleton className="w-[70%] h-16 mb-4" />
      <Skeleton className="w-[70%] h-8 mb-12" />
      <Skeleton className="w-full h-8 mb-4" />
      <Skeleton className="w-[70%] h-8 mb-4" />
      <Skeleton className="w-[50%] h-8 mb-12" />
      <Skeleton className="w-[70%] h-8 mb-4" />
      <Skeleton className="w-[70%] h-16 mb-4" />
      <Skeleton className="w-[70%] h-8 mb-12" />
    </>
  );
};

export default PaymentFormSkeleton;
