import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface ErrorsLocationState {
  errors?: string[]
}

const useError = (): undefined | string[] => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const state_ = location.state as ErrorsLocationState
    if (!state_?.errors) navigate(-1)
  })

  return (location.state as ErrorsLocationState)?.errors
}

export default useError
