import { SVGProps } from "react";

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.646 6.453c.25.272.25.711 0 .982L10.389 18.547a.607.607 0 0 1-.906 0L4.354 12.99a.736.736 0 0 1 0-.982.607.607 0 0 1 .907 0l4.675 5.064 9.803-10.62a.607.607 0 0 1 .907 0Z"
      fill="#27252A"
    />
  </svg>
);

export default SvgCheck;
