import styled from 'styled-components'
import Button from '../../../Button'
import { H3 } from '../../../Heading'
import { useDeleteUser } from './hooks'

interface DeleteConfirmationProps {
  close?: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
`

const Title = styled(H3)`
  text-align: center;
`

const Text = styled.span`
  text-align: center;
`

const Error = styled.span`
  color: ${props => props.theme.error};
  font-weight: 600;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 31rem;
  height: 6.8rem;
  margin: 0 auto;

  & > * {
    width: 12rem;
  }
`

const DeleteConfirmation = ({ close }: DeleteConfirmationProps) => {
  const { deleteUser, loading, error } = useDeleteUser()

  return (
    <Wrapper>
      <Title>Delete my account</Title>
      <Text>Are you sure you want to delete the account?</Text>
      {error && <Error>{error}</Error>}
      <ButtonsWrapper>
        <Button variant="secondary" onClick={close}>Cancel</Button>
        <Button disabled={loading} onClick={deleteUser}>Yes, delete</Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default DeleteConfirmation
