import { useNavigate } from 'react-router-dom'

interface UseLogOutReturn {
  logout: () => void
}

const useLogOut = (): UseLogOutReturn => {
  const navigate = useNavigate()

  const logout = () => {
    localStorage.clear()
    navigate('/')
  }

  return { logout }
}

export {
  useLogOut,
}
