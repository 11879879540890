import { MouseEventHandler, ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import { media } from '../../../styles'

interface PageWrapperProps {
  children?: ReactElement
  onClick: MouseEventHandler<HTMLElement>
}

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100vw;
  height: 100vh;
  //noinspection CssInvalidPropertyValue
  height: -webkit-fill-available;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(39, 37, 42, 0.7);
  z-index: 2;

  ${media.desktop} {
    justify-content: center;
    align-items: center;
  }
`

const PageWrapper = (props: PageWrapperProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'inherit'
    }
  })

  return <Wrapper {...props} />
}

export default PageWrapper
