import styled from 'styled-components'
import { media } from '../styles'

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  
  ${media.desktop} {
    width: 50%;
    min-width: 59rem;
  }
`

export default LoginWrapper
