import { useLazyQuery } from "@apollo/client";
import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { notEmptyValidator, nameValidator } from "../../../helpers/validators";
import { formatError } from "../helpers";
import { UseRegistrationType } from "../types";
import { UK_COMPANY_NUMBER } from "../utils/CRNRegexp";
import useCheckExistent from "./useCheckExistent";
import useRegister from "./useRegister";
import { GetCompanyNameByCrnQueryDocument } from "../../../datasources/graphql/operations";

const useRegistration: UseRegistrationType = () => {
  const [agreement, setAgreement] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyCRN, setCompanyCRN] = useState("");
  const { exist, loading: dataLoading } = useCheckExistent();
  const { register, success, loading, error, reset } = useRegister();
  const navigate = useNavigate();

  const [
    getCompanyNameByCRN,
    { data: companyNameData, loading: companyNameLoading },
  ] = useLazyQuery(GetCompanyNameByCrnQueryDocument, {
    variables: { crn: companyCRN },
  });

  useEffect(() => {
    if (exist) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exist, dataLoading]);

  useEffect(() => {
    if (success) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const beforeChange = () => {
    if (error) reset();
  };

  const toggleAgreement = (value: boolean) => {
    beforeChange();
    if (value !== agreement) {
      setAgreement(value);
    }
  };

  const changeFirstName: ChangeEventHandler<HTMLInputElement> = (e) => {
    beforeChange();
    const value = e.target.value;
    if (value !== firstName) {
      setFirstName(value);
    }
  };

  const changeLastName: ChangeEventHandler<HTMLInputElement> = (e) => {
    beforeChange();
    const value = e.target.value;
    if (value !== lastName) {
      setLastName(value);
    }
  };

  const changeCompanyName: ChangeEventHandler<HTMLInputElement> = async (e) => {
    beforeChange();
    const formattedValue = e?.currentTarget?.value.trim();
    try {
      if (
        formattedValue.match(UK_COMPANY_NUMBER)?.[0].length ===
        formattedValue.length
      ) {
        await getCompanyNameByCRN({
          variables: { crn: formattedValue },
        });
        setCompanyCRN(formattedValue);
      } else if (companyCRN) {
        setCompanyCRN("");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (isFormFilled()) {
      register({
        firstName,
        lastName,
        crn: companyCRN,
      });
    }
  };

  const isFormFilled = () =>
    Boolean(
      firstName &&
        nameValidator(firstName) &&
        lastName &&
        nameValidator(lastName) &&
        companyCRN &&
        notEmptyValidator(companyCRN) &&
        companyNameData?.searchCompany.name &&
        agreement
    );

  return {
    toggleAgreement,
    formFilled: isFormFilled(),
    onChange: {
      changeFirstName,
      changeLastName,
      changeCompanyName,
    },
    submit,
    loading,
    error: formatError(error),
    companyName: companyCRN ? companyNameData?.searchCompany.name : null,
    companyLoading: companyNameLoading,
    companyNameError: companyCRN ? companyNameData?.searchCompany.error : null,
  };
};

export default useRegistration;
