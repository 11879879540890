import styled from "styled-components";
import { H1, H3 } from "../components/Heading";
import Page from "../components/Page";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotFound = () => (
  <Page>
    <Wrapper>
      <H1>404</H1>
      <H3>Page not found</H3>
    </Wrapper>
  </Page>
);

export default NotFound;
