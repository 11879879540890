import styled from 'styled-components'
import WectoryLogoImage from '../assets/images/wectoryLogo.svg'
import { ImageProps } from '../helpers/propTypes'
import Profile from './Profile'

interface HeaderProps {
  showProfile?: boolean
}

const StyledHeader = styled.header`
  display: flex;
  width: 100%;
  height: 7rem;
  padding: 2.6rem 3rem;
  justify-content: space-between;
`

const LogoLink = styled.a`
  text-decoration: none;
`

const StyledLogo = styled.img<ImageProps>`
  width: 10rem;
  height: 2.4rem;
`

const Header = ({ showProfile }: HeaderProps) => {
  return (
    <StyledHeader>
      <LogoLink href='https://wectory.com/'>
        <StyledLogo src={WectoryLogoImage} alt="Wectory logo" />
      </LogoLink>
      {showProfile && <Profile />}
    </StyledHeader>
  )
}


export default Header
