import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useState,
} from 'react'
import styled from 'styled-components'
import { stopPropagationOnClick } from '../../helpers'
import { media } from '../../styles'
import PageWrapper from './components/PageWrapper'
import ModalContext from './Context'

interface ModalProviderProps {
  children: ReactElement
}

interface WithCloseProps {
  close: () => void
}

const Wrapper = styled.div`
  width: 100%;
  height: min-content;
  padding: 2rem 2rem 4rem;
  background-color: white;
  border-radius: 2rem 2rem 0 0;
  
  ${media.desktop} {
    width: 37.3rem;
    height: min-content;
    padding: 2rem;
    border-radius: 2rem;
  }
`

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<ReactElement<WithCloseProps>>(<div />)
  const close = () => {
    if (isOpen) {
      setContent(<div />)
      setIsOpen(false)
    }
  }
  const open = (content: ReactElement) => {
    setContent(content)
    setIsOpen(true)
  }

  return (
    <ModalContext.Provider value={{ open, close }}>
      {children}
      {
        isOpen &&
          <PageWrapper onClick={close}>
              <Wrapper onClick={stopPropagationOnClick}>
                {Children.map(content, child => (
                  isValidElement(child) ? cloneElement(child, { close }) : child
                ))}
              </Wrapper>
          </PageWrapper>
      }
    </ModalContext.Provider>
  )
}

export default ModalProvider
