import styled from "styled-components";
import { H3 } from "../../../components/Heading";

const PaymentSetup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 48px;
    height: 48px;
    margin-bottom: 48px;
  }

  ${H3} {
    margin-bottom: 20vh;
  }
`;

export default PaymentSetup;
