import {
  ChangeEventHandler,
  ClipboardEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { fillEmpty, isNum } from './helpers'

interface ValueType {
  value: string
  ref: MutableRefObject<any>
}

interface UseCodeInputResult {
  values: ValueType[]
  keyUp: KeyboardEventHandler
  keyDown: KeyboardEventHandler
  change: ChangeEventHandler<HTMLInputElement>
  onPaste: ClipboardEventHandler<HTMLInputElement>
}

const useCodeInput = (
  code: string, onChange: () => void, onFill: (value: string) => void,
): UseCodeInputResult => {
  const [values, setValues] = useState(['', '', '', '', '', ''])
  const values_: ValueType[] = [
    { value: values[0], ref: useRef() },
    { value: values[1], ref: useRef() },
    { value: values[2], ref: useRef() },
    { value: values[3], ref: useRef() },
    { value: values[4], ref: useRef() },
    { value: values[5], ref: useRef() },
  ]
  useEffect(() => {
    focusOnFirstEmpty()
    if (code && code.length === 6) {
      const codeArr = code.split('')
      setValues(codeArr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  useEffect(() => {
    const newCode = values.join('')
    if (newCode.length === 6 && newCode !== code) {
      onFill(newCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, values])

  const filled = (): boolean => values.every(value => value !== '')

  const getFirstEmptyIndex = (): number => (
    filled() ? 5 : values.join('').length
  )

  const focusOnFirstEmpty = (): void => {
    values_[getFirstEmptyIndex()].ref.current.focus()
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Backspace') {
      setValues(fillEmpty(values.join('').slice(0, -1).split('')))
    } else if (isNum(e.key) && !filled()) {
      setValues(fillEmpty(values.join('').concat(e.key).split('')))
    }
  }

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Backspace') {
      focusOnFirstEmpty()
    } else if (isNum(e.key) && !filled()) {
      focusOnFirstEmpty()
    }
  }

  const onPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
    const data = e.clipboardData.getData('text')
    if (data && isNum(data)) {
      setValues(fillEmpty(data.split('').slice(0, 6)))
    }
  }

  const onChange_: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
  }

  return {
    values: values_,
    keyUp: onKeyUp,
    keyDown: onKeyDown,
    change: onChange_,
    onPaste,
  }
}

export {
  useCodeInput,
}
