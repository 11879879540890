import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ModalContext from "../ModalProvider/Context";
import DeleteConfirmation from "./components/DeleteConfirmation";

interface UseProfileResult {
  visible: boolean;
  open: () => void;
  close: () => void;
  saved: boolean | null;
  onSave: () => void;
  onDelete: () => void;
}

const useProfile = (): UseProfileResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmed, setConfirmed] = useState<boolean | null>(null);
  const [saved, setSaved] = useState<boolean | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const { open: openModal } = useContext(ModalContext);

  useEffect(() => {
    const sourcePath = searchParams.get("source");
    if (!sourcePath) return;
    const success = searchParams.get("success");

    setConfirmed(success === "true");

    const sp = new URLSearchParams(searchParams);
    sp.delete("source");
    sp.delete("success");
    setSearchParams(sp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (confirmed !== null && localStorage.getItem("unsaved_data")) {
      if (confirmed) {
        localStorage.removeItem("unsaved_data");
        setSaved(true);
      } else {
        setSaved(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  useEffect(() => {
    if (saved === true) {
      setTimeout(() => {
        setSaved(null);
      }, 5000);
    }
  }, [saved]);

  const open = () => {
    if (!visible) {
      setVisible(true);
      document.body.style.overflow = "hidden";
    }
  };

  const close = () => {
    if (visible) {
      setVisible(false);
      document.body.style.overflow = "inherit";
    }
  };

  const onSave = () => {
    if (!saved) {
      setSaved(true);
    }
  };

  const onDelete = () => {
    openModal(<DeleteConfirmation />);
  };

  return { visible, open, close, saved, onSave, onDelete };
};

export { useProfile };
