import { ReactNode } from 'react'
import styled from 'styled-components'
import { useCheckbox } from './logic'

interface StyledCheckboxProps {
  checked: boolean
}

interface CheckboxProps {
  children: ReactNode
  onCheck: (value: boolean) => void
  defaultChecked?: boolean
}


const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  gap: 1rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  align-items: center;
  letter-spacing: 0.015em;
  cursor: pointer;

  &::before {
    content: '';
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    border: .1rem solid #000000;
    border-radius: 2rem;
    background-color: ${props => (
      props.checked ? props.theme.accent : 'inherit'
    )};
  }
`

const ChildrenWrapper = styled.span`
  white-space: pre-wrap;
`

const AgreementCheckbox = ({ children, onCheck, defaultChecked }: CheckboxProps) => {
  const [checked, toggle] = useCheckbox(false, onCheck)

  return (
    <StyledCheckbox defaultChecked={defaultChecked} checked={checked} onClick={toggle}>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </StyledCheckbox>
  )
}

export default AgreementCheckbox
