import styled from 'styled-components'
import { dateFormatter, poundFormatter } from '../../../../../helpers'
import { PaymentData } from '../types'

interface PaymentsProps {
  payments: PaymentData[]
}

const PaymentsTable = styled.table`
  width: 100%;
  border: none;

  & tbody tr:not(:first-child) td {
    padding-top: 1rem;
  }
  
  & tbody tr:not(:last-child) td {
    padding-bottom: 1rem;
  }
  
  & tbody tr td:first-child {
    // to center amount on one line each month
    width: min(40vw, 50rem);
  }
  
  & tbody tr td:last-child {
    text-align: right;
  }
`

PaymentsTable.defaultProps = {
  cellPadding: 0,
  cellSpacing: 0,
}

const Payments = ({ payments }: PaymentsProps) => {
  return (
    <PaymentsTable>
      <tbody>
      {payments.map(({date, amount, status}, index) => (
        <tr key={`payment_${index}`}>
          <td key={`payment_${index}_date`}>{dateFormatter.format(date)}</td>
          <td key={`payment_${index}_amount`}>{poundFormatter.format(amount)}</td>
          <td key={`payment_${index}_status`}>{status}</td>
        </tr>
      ))}
      </tbody>
    </PaymentsTable>
  )
}

export default Payments
