import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

const HowToUseModal = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-min border border-accent">
          Read
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-white md:border md:border-black p-0 md:!rounded-3xl max-w-none w-full h-full md:w-[70vw] md:h-[90vh]">
        <iframe
          src="https://www.wectory.com/agent_how_to_use"
          title="How to Use"
          className="w-full h-full md:rounded-3xl"
        />
      </DialogContent>
    </Dialog>
  );
};

export default HowToUseModal;
