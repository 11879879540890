import { useEffect, useState } from 'react'
import { groupPaymentsByMonths } from './helpers'
import { PaymentsGroupedByMonths, RawPaymentData } from './types'
import { GetRewardsQueryDocument } from '../../../../datasources/graphql/operations'
import { useQuery } from '@apollo/client'

interface UsePayoutHistoryResult {
  payments: PaymentsGroupedByMonths | null
  error: string | null
}

const usePayoutHistory = (): UsePayoutHistoryResult => {
  const [payments, setPayments] = useState<RawPaymentData[] | null>(null)
  const { data, error } = useQuery(GetRewardsQueryDocument)

  useEffect(() => {
    if (data && data.rewards && data.rewards.length > 0) {
      setPayments(data.rewards)
    }
  }, [data])

  return {
    payments: payments && groupPaymentsByMonths(payments),
    error: (error || null) && 'Error loading data',
  }
}

export {
  usePayoutHistory,
}
