import { ChangeEventHandler, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getFingerprint } from '../../helpers'
import { saveToken, TokenType } from '../../helpers/jwt'
import emailValidator from '../../helpers/validators/emailValidator'
import { LoginMutationDocument } from '../../datasources/graphql/operations'
import { useMutation } from '@apollo/client'

type UseLoginType = () => {
  email: string
  setEmail: ChangeEventHandler<HTMLInputElement>
  submit: (event: SyntheticEvent) => void
  data: { auth?: string | null }
  loading: boolean
  error: any
  submitDisabled: boolean
}

interface ErrorExtensions {
  code: string
  response: {
    body: {
      errors: string[]
    } | any
  }
  [attributeName: string]: unknown
}

const useLogin: UseLoginType = () => {
  const [email_, setEmail_] = useState('')
  const [mutateFunction, { data, loading, error, reset }] = useMutation(LoginMutationDocument)
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      if (data.auth) {
        saveToken(TokenType.LOGIN, data.auth)
        navigate({
          pathname: '/confirm',
          search: window.location.search
        })
      } else if (error) {
        console.error(error.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
      const extensions = error.graphQLErrors[0].extensions as ErrorExtensions
      if (
        extensions
        && extensions.code === 'INTERNAL_SERVER_ERROR'
        && extensions.response.body.errors
      ) {
        navigate('/error', {
          replace: false,
          state: { errors: extensions.response.body.errors },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const updateEmail: ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail_(event.target.value.toLowerCase())
    reset()
  }

  const submitIsDisabled = (): boolean => Boolean(
    data || email_ === '' || loading || error || !emailValidator(email_),
  )

  const submitEmail = async (event: SyntheticEvent) => {
    event.preventDefault()
    if (!submitIsDisabled()) {
      await mutateFunction({
        variables: { email: email_, fingerprint: getFingerprint() },
      })
    }
  }

  return {
    email: email_,
    setEmail: updateEmail,
    submit: submitEmail,
    submitDisabled: submitIsDisabled(),
    data: data || {auth: ''},
    loading,
    error,
  }
}

export { useLogin }
