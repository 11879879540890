import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ModalProvider from "./components/ModalProvider";
import Confirm from "./Routes/Confirm";
import Dashboard from "./Routes/Dashboard";
import ErrorPage from "./Routes/ErrorPage";
import LoginEmail from "./Routes/LoginEmail";
import NotFound from "./Routes/NotFound";
import PaymentSetup from "./Routes/PaymentCheck";
import PaymentSetupFinished from "./Routes/PaymentCheck/PaymentSetupSuccess";
import Registration from "./Routes/Registration";
import PaymentSetupFixed from "./Routes/PaymentCheck/PaymentSetupFixed";

const theme = {
  accent: "#4F4CFF",
  pageBackground: "#F6F6F6",
  background: "#FFFFFF",
  textMain: "#27252A",
  textSecondary: "#AEAEAE",
  error: "#FF3838",
};

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<LoginEmail />} />
            <Route path="/confirm" element={<Confirm />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/payment-setup/:id" element={<PaymentSetup />} />
            <Route
              path="/payment-setup/success"
              element={<PaymentSetupFinished />}
            />
            <Route
              path="/payment-setup/to-the-landlord"
              element={<PaymentSetupFixed />}
            />
            <Route
              path="/payment-setup/failed"
              element={<PaymentSetupFinished failed />}
            />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ModalProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
