import { ReactNode } from "react";
import { media } from "../styles";
import Header from "./Header";
import styled from "styled-components";

interface WrapperProps {
  children?: ReactNode;
  showProfile?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  min-height: 100vh;
  // padding-bottom: 15vh;
  color: ${(props) => props.theme.textMain};
`;

const ContainerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  justify-items: center;

  ${media.desktop} {
    flex: unset;
    display: unset;
    flex-direction: unset;
    height: 100%;
    justify-content: unset;
    justify-items: unset;
  }
`;

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  min-width: min-content;
  padding: 0 2rem;
  margin: auto 0;
  box-sizing: border-box;

  ${media.desktop} {
    max-width: 118rem;
    min-width: 59rem;
    margin: 7rem auto 0 auto;
    box-sizing: content-box;
  }
`;

const Page = ({ children, showProfile }: WrapperProps) => {
  return (
    <Wrapper>
      <Header showProfile={showProfile} />
      <ContainerWrapper>
        <Container>{children}</Container>
      </ContainerWrapper>
      <footer className="grow flex items-end justify-center p-8 text-2xl">
        <a
          className="decoration-transparent text-foreground"
          href="https://www.wectory.com/ara"
          target="_blank"
          rel="noreferrer"
        >
          Terms and conditions
        </a>
      </footer>
    </Wrapper>
  );
};

export default Page;
