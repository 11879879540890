import { useNavigate } from "react-router-dom";
import { PaymentSetupStyled, StyledLogo } from ".";
import WectoryLogoImage from "../../assets/images/wectoryLogo.svg";
import { H3 } from "../../components/Heading";
import SvgCheck from "./CheckIcon";
import SvgCloseBig from "./CloseBigIcon";
import PaymentSetup from "./styled/PaymentSetup";
import { Button } from "@/components/ui/button";

type PaymentSetupFinishedProps = { className?: string; failed?: boolean };

const PaymentSetupFinished = ({
  className,
  failed,
}: PaymentSetupFinishedProps) => {
  const navigate = useNavigate();

  return (
    <PaymentSetupStyled className={className}>
      <StyledLogo src={WectoryLogoImage} />
      <PaymentSetup>
        {failed ? <SvgCloseBig /> : <SvgCheck />}
        <H3>{`The setup was ${
          failed ? "failed" : "completed successfully"
        }`}</H3>
        <Button onClick={() => navigate("/", { replace: true })}>
          Return to homepage
        </Button>
      </PaymentSetup>
      <div></div>
    </PaymentSetupStyled>
  );
};

export default PaymentSetupFinished;
