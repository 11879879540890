import { Button } from "@/components/ui/button";
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Check, Copy, Loader2 } from "lucide-react";
import { useState } from "react";

type GenerateCodeProps = {
  code: string;
  loading: boolean;
};

const GenerateCode = ({ code, loading }: GenerateCodeProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="space-y-8">
      <DialogHeader>
        <DialogTitle className="mb-4 text-left text-balance leading-10 pr-[10%]">
          Referral code has been generated
        </DialogTitle>
      </DialogHeader>
      <DialogDescription className="space-y-4 text-inherit">
        <p className="text-2xl font-medium">
          Your personalised referral code has been successfully generated!
          Please copy the code below and send it to the landlord. This code will
          enable the landlord to apply for rent upfront under special terms.
        </p>
        <p className="text-2xl font-bold !mt-8">📝 Instructions for Use:</p>
        <ol className="text-2xl list-decimal pl-8 font-medium">
          <li>Copy the code.</li>
          <li>Send the code to the landlord.</li>
          <li>
            The landlord should use this code in their application to receive
            rent upfront.
          </li>
        </ol>
        <hr className="!my-8" />
        <div className="p-4 rounded-lg flex flex-wrap flex-row items-start md:items-center justify-start gap-8 !mt-0">
          <span className="text-4xl basis-full">🔑 Your Referral Code: </span>
          <pre className="text-4xl bg-gray-100 md:max-w-[50%] border border-border p-4 rounded-3xl grow text-center">
            {loading ? (
              <span className="flex gap-2 justify-center w-full">
                <Skeleton className="bg-white w-5 h-10" />
                <Skeleton className="bg-white w-5 h-10" />
                <Skeleton className="bg-white w-5 h-10" />
                <Skeleton className="bg-white w-5 h-10" />
                <Skeleton className="bg-white w-5 h-10" />
              </span>
            ) : (
              code
            )}
          </pre>
          {loading ? (
            <Button variant="outline" size="icon" type="button">
              <Loader2 className="animate-spin" />
            </Button>
          ) : (
            <Button variant="outline" size="icon" onClick={handleCopy}>
              {copied ? <Check size={24} /> : <Copy size={24} />}
            </Button>
          )}
        </div>
      </DialogDescription>
    </div>
  );
};

export default GenerateCode;
