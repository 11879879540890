import { ApolloError, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { getToken, saveToken, TokenType } from "../../../helpers/jwt";
import { CheckAuthCodeMutationDocument, UpdateAgentMutationDocument } from "../../../datasources/graphql/operations";

interface UseCheckResult {
  check: (code: string) => void;
  success: boolean;
  loading: boolean;
  error: ApolloError | undefined;
}

const useCheck = (): UseCheckResult => {
  const [update, { data: updateData, error: updateError }] =
    useMutation(UpdateAgentMutationDocument);
  const [check, { data: checkData, error: checkError }] =
    useMutation(CheckAuthCodeMutationDocument);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const hasAccessToken = Boolean(localStorage.getItem("auth_access_token"));

  const onSuccess = () => {
    setLoading(false);
    setSuccess(true);
  };

  const onFailed = (error: ApolloError) => {
    console.error(error);
    setLoading(false);
    setError(error);
    setSuccess(false);
  };

  useEffect(() => {
    const data = updateData;
    if (data && data.updateAgent) {
      localStorage.removeItem("confirmation_session");
      onSuccess();
    } else if (updateError) {
      onFailed(updateError);
    }
  }, [updateData, updateError]);

  useEffect(() => {
    const data = checkData;
    if (data && data.authCheck) {
      saveToken(TokenType.ACCESS, data.authCheck.jwtAccess ?? "");
      saveToken(TokenType.REFRESH, data.authCheck.jwtRefresh ?? "");
      localStorage.setItem("auth_created", data.authCheck.created ? "1" : "0");
      localStorage.removeItem("auth_login_token");
      onSuccess();
    } else if (checkError) {
      onFailed(checkError);
    }
  }, [checkData, checkError]);

  const check_ = (code: string) => {
    setLoading(true);
    const unsavedData = JSON.parse(
      localStorage.getItem("unsaved_data") || "null"
    );
    const hash = localStorage.getItem("hash") || "null";
    if (hasAccessToken && unsavedData) {
      // noinspection JSIgnoredPromiseFromCall
      update({
        variables: { code, ...unsavedData, hash },
        context: {
          extraHeaders: {
            "x-confirmation-id": localStorage.getItem("confirmation_session"),
          },
        },
      });
    } else {
      // noinspection JSIgnoredPromiseFromCall
      check({
        variables: { code },
        context: { extraToken: getToken(TokenType.LOGIN) },
      });
    }
  };

  return { check: check_, success, loading, error };
};

export default useCheck;
