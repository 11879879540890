import styled from "styled-components";
import ProfileImage from "../../assets/images/profile.svg";
import Hint from "./components/Hint";
import Settings from "./components/Settings";
import { useProfile } from "./hooks";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 2rem;
`;

const ProfileIcon = styled.img`
  width: 1.6rem;
  height: 2rem;
  cursor: pointer;
`;

const Profile = () => {
  const { visible, open, close, saved, onDelete } = useProfile();

  return (
    <Wrapper>
      {saved !== null &&
        (saved ? (
          <Hint>Your data has been saved</Hint>
        ) : (
          <Hint error>Error saving data</Hint>
        ))}
      <ProfileIcon src={ProfileImage} alt="Profile" onClick={open} />
      {visible && <Settings close={close} onDelete={onDelete} />}
    </Wrapper>
  );
};

export default Profile;
