import { Checkbox } from "@/components/ui/checkbox";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  Form,
} from "@/components/ui/form";

import {
  AgentAgreement,
  AgentPaymentMethod,
  AgentsPaymentSetupData,
  BankDetails,
  DocumentType,
  StartAgentsPaymentsSetupMutationDocument,
} from "@/datasources/graphql/operations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { Card } from "@/components/ui/card";
import BankDetailsTable from "./bank-details-table";
import FileInput from "./file-input";

import PaymentFormModal from "./payment-form-modal";
import { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import RentalPaymentModal from "./rental-payment-modal";
import {
  PAYMENTS_SET_UP,
  TRANSFER_AMOUNT,
  viewIds,
} from "../constants/agreements";

const formSchema = z.object({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  docs: z
    .array(z.instanceof(File))
    .nonempty({ message: "At least one file is required" }),
  agreement: z.array(
    z.boolean().refine((value) => value === true, "This field is required")
  ),
});

export type FormSchema = z.infer<typeof formSchema>;

type PaymentFormProps = {
  agentAgreements: AgentAgreement[];
  bankDetails: BankDetails;
  application: AgentsPaymentSetupData["application"];
  secret: string;
  rentalPayment: number;
};

const Island = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-white p-8 rounded-3xl shadow-[0_.2rem_1.5rem_rgba(89,_89,_89,_0.1)]">
    {children}
  </div>
);

const PaymentForm = ({
  agentAgreements,
  bankDetails,
  secret,
  rentalPayment,
  application,
}: PaymentFormProps) => {
  const navigation = useNavigate();
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      agreement: agentAgreements.map((agreement) => agreement.agreed),
      docs: [],
      firstName: "",
      lastName: "",
    },
  });

  const checkboxesSorted = useMemo(() => {
    if (agentAgreements.length === 0) {
      return [];
    }
    return agentAgreements.toSorted(
      (a, b) =>
        viewIds.indexOf(a.viewId || "") - viewIds.indexOf(b.viewId || "")
    );
  }, [agentAgreements]);

  const paymentSetUpCheckbox = checkboxesSorted.find(
    (checkbox) => checkbox.viewId === PAYMENTS_SET_UP
  );

  const paymentSetUpCheckboxIndex = checkboxesSorted.findIndex(
    (checkbox) => checkbox.viewId === PAYMENTS_SET_UP
  );

  const [startAgentsPaymentsSetup, { error }] = useMutation(
    StartAgentsPaymentsSetupMutationDocument
  );

  const [openModal, setOpenModal] = useState(false);
  const [openRentalPaymentsModal, setOpenRentalPaymentsModal] = useState(false);
  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    await startAgentsPaymentsSetup({
      variables: {
        secret,
        data: {
          document: {
            documents: data.docs,
            targetId: secret,
            type: DocumentType.AgentLandlordAgreement,
          },
          paymentMethod: AgentPaymentMethod.BankDetails,
          firstName: data.firstName,
          lastName: data.lastName,
          agentAgreements: agentAgreements.map((agreement, index) => ({
            agreed: data.agreement[index],
            id: agreement.id,
          })),
        },
      },
    });

    navigation("/payment-setup/success", { replace: true });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        id="payment-form"
        className="my-8 space-y-12"
      >
        <Island>
          <FormDescription>
            The landlord you're collaborating with is seeking an advance from
            Wectory Ltd based on the tenancy agreement. To ensure everything
            goes smoothly, kindly review and confirm the details below:
          </FormDescription>
          <fieldset className="!mt-4 space-y-8 ">
            {checkboxesSorted
              .toSpliced(paymentSetUpCheckboxIndex, 1)
              .map((agreement, index) => {
                if (agreement.viewId === TRANSFER_AMOUNT) {
                  return (
                    <FormField
                      key={agreement.title}
                      control={form.control}
                      name={`agreement.${index}`}
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex space-x-6">
                            <FormControl>
                              {/* @ts-ignore */}
                              <Checkbox
                                {...field}
                                checked={field.value}
                                // defaultChecked={agreement.agreed}
                                onCheckedChange={field.onChange}
                                id={agreement?.title}
                                className="mt-2"
                              />
                            </FormControl>
                            <FormLabel
                              htmlFor={agreement?.title}
                              className="leading-8"
                            >
                              {agreement?.title} If the amount is different,
                              please click{" "}
                              <button
                                type="button"
                                className="underline text-accent"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenRentalPaymentsModal(true);
                                }}
                              >
                                change
                              </button>
                            </FormLabel>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  );
                }
                return (
                  <FormField
                    key={agreement.title}
                    control={form.control}
                    name={`agreement.${index}`}
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex space-x-6">
                          <FormControl>
                            {/* @ts-ignore */}
                            <Checkbox
                              {...field}
                              checked={field.value}
                              // defaultChecked={agreement.agreed}
                              onCheckedChange={field.onChange}
                              id={agreement.title}
                              className="mt-2"
                            />
                          </FormControl>
                          <FormLabel
                            htmlFor={agreement.title}
                            className="leading-8"
                          >
                            {agreement.title}
                          </FormLabel>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                );
              })}
          </fieldset>
        </Island>

        <Island>
          <FormDescription>
            Please attach a document/agreement that verifies your collaboration
            with the landlord
          </FormDescription>
          <FormField
            control={form.control}
            name="docs"
            render={({ field }) => {
              const { value, ...fieldProps } = field;
              return (
                <FormItem className="!mt-4 ">
                  <FormControl>
                    <FileInput
                      {...fieldProps}
                      id="docs"
                      onFieldChange={fieldProps.onChange}
                      accept=".gif,.pdf,.tiff,.tif,.jpg,.jpeg,.png,.bmp,.webp"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </Island>

        <Island>
          <FormDescription>
            To set up a standing order, kindly use the following bank details:
          </FormDescription>
          <Card className="p-4 !mt-4 ">
            <BankDetailsTable
              bankDetails={bankDetails}
              application={application}
            />
          </Card>
          {paymentSetUpCheckbox && (
            <FormField
              control={form.control}
              name={`agreement.${paymentSetUpCheckboxIndex}`}
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center space-x-6 mt-8">
                    <FormControl>
                      {/* @ts-ignore */}
                      <Checkbox
                        {...field}
                        checked={field.value}
                        // defaultChecked={agreement.agreed}
                        onCheckedChange={field.onChange}
                        id="bankDetails"
                      />
                    </FormControl>
                    <FormLabel htmlFor="bankDetails">
                      {paymentSetUpCheckbox?.title}
                    </FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </Island>

        <p className="font-bold text-2xl mt-4">
          Please ensure all details are correct before submission.
        </p>

        <RentalPaymentModal
          openModal={openRentalPaymentsModal}
          setOpenModal={setOpenRentalPaymentsModal}
          rentalPayment={rentalPayment}
          secret={secret}
        />
        <PaymentFormModal
          form={form}
          openModal={openModal}
          setOpenModal={setOpenModal}
          loading={form.formState.isSubmitting}
          error={error}
        />
      </form>
    </Form>
  );
};

export default PaymentForm;
