import { useEffect, useState } from 'react'
import { Timer } from '../types'

interface UseCountdownResult {
  timer: Timer,
  finished: boolean,
  restart: () => void,
}

const useCountdown = (initialSeconds: number): UseCountdownResult => {
  const [seconds, setSeconds] = useState(initialSeconds)
  const [running, setRunning] = useState(true)
  useEffect(() => {
    if (!running) return
    const interval = setInterval(() => {
      setSeconds(s => s - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [running])
  useEffect(() => {
    if (seconds < 1 && running) {
      setRunning(false)
    }
  }, [seconds, running])

  const restart = () => {
    setSeconds(initialSeconds)
    setRunning(true)
  }

  return {
    timer: {
      minutes: String((seconds - (seconds % 60)) / 60).padStart(2, '0'),
      seconds: String(seconds % 60).padStart(2, '0'),
    },
    finished: !running,
    restart,
  }
}

export default useCountdown
